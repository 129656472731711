<template>
  <div id="real">
    <!-- :style='state == 1 || state == 3?"opacity:0.5;":"1"' -->

    <template v-for='item in dataInput'>
        <textInput class='p32' >
          <field
          :border='false'
          v-model="item.val"
          :label="item.title"
           :placeholder="item.placeholder"
           class='flex-container'
           :maxlength='item.len'
           rule
           :type='item.type'
           :disabled='state == 1 || state == 3?true:false'
           />
        </textInput>

    </template>
    <template v-if='user.member_mobile == "" || user.member_mobile == null'>
      <textInput class='p32'  >
        <field
         :border='false'
         label="手机号"
         placeholder="请输入你的手机号码"
         class='flex-container'
         maxlength='11'
         type='digit'
         v-model="tel"
         />
      </textInput>
      <textInput class='p32'  >
        <field
         :border='false'
         label="验证码"
         placeholder="请输入验证码"
         class='flex-container'
         maxlength='6'
         type='digit'
         v-model='yzm'
         />
         <template>
            <p class='yzm' @click='getYZM'>获取验证码</p>
        </template>
      </field>
      </textInput>

    </template>




      <div class="real">

        <div class="real_li flex-container">
            <div class="left">
                <h2>头像面</h2>
                <span>上传您身份证头像面</span>
                <span v-if='state == 0' >{{uploadImg.img1?'已上传':'未上传'}}</span>
                <span class='yes' v-else-if='state == 3'>审核完成</span>
                <span class='no' v-else-if='state == 2'>审核失败</span>
                <span class='no' v-else-if='state == 1'>审核中</span>
            </div>
            
            <van-uploader :after-read="upload" name='2' multiple  :disabled='state == 1 || state == 3?true:false'>
              <div class="right">
                  <img src="@image/me/real_border.png" class='border'>
                  <img :src="uploadImg.img1==''?require('@image/me/real_img2.png'):uploadImg.img1" class='show'>
                  <img v-if='uploadImg.img1.length > 0 ' src="@image/me/real_upload.png" class='upload'>
              </div>
            </van-uploader>

        </div>

        <div class="real_li flex-container">
            <div class="left">
                <h2>国徽面</h2>
                <span>上传您身份证国徽面</span>
                  <span v-if='state == 0'>{{uploadImg.img2?'已上传':'未上传'}}</span>
                <span class='yes' v-else-if='state == 3'>审核完成</span>
                <span class='no' v-else-if='state == 2'>审核失败</span>
                <span class='no' v-else-if='state == 1'>审核中</span>
            </div>
            <van-uploader :after-read="upload"  name='3' multiple :disabled='state == 1 || state == 3?true:false'>
              <div class="right">
                <img src="@image/me/real_border.png" class='border'>
                <img :src="uploadImg.img2==''?require('@image/me/real_img1.png'):uploadImg.img2" class='show'>
                <img  v-if='uploadImg.img2.length > 0 ' src="@image/me/real_upload.png" class='upload'>
              </div>
            </van-uploader>
        </div>
      </div>
      <div class="btn_solid">
        <div v-if='state == 0' class="btn" @click='yesAuth'>提交申请</div>
        <div v-else-if='state == 3' class="btn" @click='toast("十九匠商城:认证已经通过，如需修改请联系客服")'>修改资料</div>
        <div v-else-if='state == 1' class="btn" @click='toast("十九匠商城:认证审核中")'>审核中</div>
        <div v-else class="btn" @click='yesAuth'>重新申请</div>
        <!-- 重新提交 -->
      </div>

        <van-popup
        v-model="show"
        round position="bottom"
        :overlay='false'
        :style="{ height: '98%' }"
        >
        <div class="real_ok">
            <img src="@image/me/ok_icon.png" alt="">
            <h1>提交成功</h1>
            <span>审核结果在3至7个工作日内完成</span>
            <div class="btn" @click='$router.go(-1)'>
              返回上一页
            </div>
        </div>
      </van-popup>

  </div>
</template>

<script>
import { Popup,Uploader,Toast,Field  } from 'vant';
import textInput from '@/components/textInput/';
import { post,form } from '@get/http'
import {getUpdateUserMobile,getMemberIndex,getBindUserMobile } from '../safe/js/'
export default {
  components: {
    vanPopup: Popup,
    vanUploader:Uploader,
    textInput,
    Field
  },
  name: "",

  computed: {

  },
  async created() {
    Toast.clear();
    Toast.loading({
        message: '加载中...',
        loadingType: 'spinner',
        overlay : true,
        forbidClick: true,
        duration : 0,
      });
    let {member_info} = await getMemberIndex()
     this.state = member_info.member_auth_state;
          this.user = member_info;
          if(this.state == 1 || this.state == 3 ){
            // this.uploadImg.img1 = this.user.member_idcard_image2_url;
            // this.uploadImg.img2  = this.user.member_idcard_image3_url;
            this.uploadImg.img1 = require('@image/me/real_img11.png');
            this.uploadImg.img2  = require('@image/me/real_img22.png');
            this.dataInput[0].val = this.user.member_truename.replace(/([\u4E00-\u9FA5]{1})([\u4E00-\u9FA5]+)$/,"$1*****")
            this.dataInput[1].val = this.user.member_idcard.replace(/^(.{4})(?:\d+)(.{4})$/,"$1******$2");
          }

          Toast.clear();

  },
  data: () => ({
    user : {},  //用户数据
    state : '0',   //实名验证状态
    show : false,
    yzm : '',   //验证码
    tel : '',   //手机
    uploadImg : {   //上传图片的路径
      img1 : '',
      img2 : ''
    },

    dataInput : [
      {
        title : '真实姓名',
        val : '',
        placeholder : '请输入真实姓名',
        type : 'text',
        len : '4'
      },
      {
        title : '身份证',
        val : '',
        placeholder : '请输入身份证号',
        type : 'text',
        len : '18'
      }

    ],
  }),
  methods: {
    async getYZM(){
      // 获取验证码
      if(this.tel.length != 11){
        Toast('十九匠商城:请输入正确的手机号码');
        return false;
      }
      if(this.tel.length != 0){
        let res = await getBindUserMobile(this.tel);
        if(res.code == 10000){
            Toast('十九匠商城:已发送验证码');
          }else{
            Toast('十九匠商城:'+ res.message);
          }
      }else{
        Toast('十九匠商城:请填写手机号码');
      }

    },
    toast(text){
      Toast(text);
    },
    async upload(file,detail) {
      Toast.loading({
        message: '加载中...',
        loadingType: 'spinner',
        overlay : true,
        forbidClick: true,
        duration : 0,
      });
      let formdata = new FormData()
      formdata.append('member_idcard_image'+ detail.name , file.file)
      formdata.append('id', 'member_idcard_image'+ detail.name )
      if(detail.name == 2){
        this.uploadImg.img1 = file.content;
      }else{
        this.uploadImg.img2 = file.content;
      }
      await form('/Member/edit_auth',{
        data : formdata
      })
      Toast.clear();
   
    },
    async yzAuth(){
      await post('/v2.Member/auth',{data : {member_truename : this.dataInput[0].val,
        member_idcard : this.dataInput[1].val,
        if_confirm : true,}})
        this.show = true;
    },
    async yesAuth(){

      if(this.dataInput[0].val == ''){
        Toast('十九匠商城：请输入真实姓名');
        return false;
      }
      if(this.dataInput[1].val == '' || this.dataInput[1].val.length < 18){
        Toast('十九匠商城：请输入正确的身份证');
        return false;
      }
      if(this.uploadImg.img1 == ''){
        Toast('十九匠商城：请上传身份证头像面');
        return false;
      }

      if(this.uploadImg.img2 == ''){
        Toast('十九匠商城：请上传身份证国徽面');
        return false;
      }

      if(this.user.member_mobile == null || this.user.member_mobile.length == 0 ){
        // 如果没绑定手机

        if(this.tel.length != 11){
          Toast('十九匠商城:请输入正确的手机号码');
          return false;
        }
        let clear = Toast.loading({
          message: '提交中...',
          forbidClick: true,
          loadingType: 'spinner',
        });

        if(this.yzm.length < 6 ){
          Toast('十九匠商城:请输入正确的验证码');
          return false;
        }
        await getUpdateUserMobile(this.yzm);
        this.yzAuth();
        Toast.clear();
        return false;

      }



          this.yzAuth();

    }

  }
}
</script>
<style lang="scss" scoped>
// @use "sass:math";
  @import '~@css/mixin.scss';
  #real{
    background:  #ededed;
    height: 100%;
    margin-bottom: rem(150);

  }
  .real{
    padding-top: rem(100);
    position: relative;
  }
  .p32{
    padding: 0 rem(32);
  }
  .real_li{
    width: rem(710);
    height: rem(340);
    background: #FFFFFF;
    border-radius: rem(20);
    padding: rem(48);
    box-sizing: border-box;
    font-weight: 500;
    margin: 0 auto rem(40);
    .left{
      width: rem(258);
      .yes{
        color: #08C260;
      }
      .no{
        color: #FF4848;
      }
      h2{
        font-size: rem(36);
        font-weight: bold;
        color: #343434;
        margin-top: rem(74);
      }
      span{
        font-size: rem(26);
       
        color: #B3B3B3;
        margin-top: rem(16);
        display: block;
      }
    }
    .right{
      width: rem(372);
      height: rem(244);
      position: relative;
      background: #f0f2f5;
      .border{
        position: absolute;
        width: rem(380);
        left:rem(-4);
        top:rem(-4);
        height: rem(250);
      }
      .show{
        width: rem(332);
        height: rem(205);
        margin:rem(20);
      }
      .upload{
        position: absolute;
        width: rem(72);
        height: rem(72);
        top:0;
        right: 0;
        bottom: 0;
        left:0;
        margin:auto;
      }
    }
  }

  .btn_solid{
    height: rem(150);
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px rem(-4) rem(20) 0px rgba(204, 204, 204, 0.15);
    border-radius: rem(20) rem(20) 0px 0px;
    position: fixed;
    bottom: 0;
    left: 0;
  }

  .btn{
    bottom: rem(32);
    width: rem(640);
    height: rem(88);
    background: #FFE5E5;
    border-radius: rem(44);
    // border-radius: rem(math.div(88, 2));
    margin: rem(32) auto 0 ;
    font-size: rem(32);
    font-weight: bold;
    color: #FF4747;

    text-align: center;line-height: rem(88);
  }

  .real_ok{
    text-align: center;
    img{

      width: rem(180);
      height: rem(180);
      display: block;
      margin: rem(160) auto  0;
    }
    h1{
      font-size: rem(36);
      font-weight: bold;
      color: #343434;
      margin: rem(44) 0 rem(24);
    }
    span{
      font-size: rem(26);
    
      font-weight: 500;
      color: #B3B3B3;

    }
  }

  .btn{
    position: fixed;
    bottom: rem(32);
    left: 0;
    right: 0;
    margin: 0 auto;
    width: rem(640);
    height: rem(88);
    background: #FFE5E5;
    border-radius: rem(44);
    // border-radius: rem(math.div(88, 2));
    font-size: rem(32);
    font-weight: bold;
    color: #FF4747;
    text-align: center;line-height: rem(88);
  }

  .yzm{
    width: rem(180);
    height: rem(58);
    background: #FFEBEB;
    border-radius: rem(28);
    font-size: rem(24);
    text-align: center;
    line-height: rem(58);
   
    color: #FF4747;
  }
  .yzm_act{
    color: #B3B3B3;
    background: #F5F5F5;
  }

</style>
